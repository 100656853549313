import DataTableColumnHeader from "@/components/working-groups-data-table/data-table-column-header";
import DataTableRowActions from "@/components/working-groups-data-table/data-table-row-actions";
import { convertStatus } from "@/lib/utils";
import { BookOpenText, CalendarClock } from "lucide-react";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import turkishStrings from "react-timeago/lib/language-strings/tr";
const formatter = buildFormatter(turkishStrings);

export const getAllWorkingGroupsColumns = (
  deleteWorkingGroup,
  updateWorkingGroup
) => [
  {
    accessorKey: "name",
    header: (props) => {
      return (
        <div className="flex  gap-2  items-center justify-start ">
          <BookOpenText size={16} />
          <span>Çalışma Grubu Adı</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const { text, color } = convertStatus(row.original.status);
      return (
        <div className="w-64  flex justify-start items-center gap-2 ">
          {/* <div className={`h-6 w-2  rounded-full ${color}`} /> */}
          <div className="">{row.original.name}</div>
        </div>
      );
    },
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-left"
        column={column}
        title="Oluşturma Zamanı"
      />
    ),
    cell: (row) => {
      return (
        <div className="w-full flex   font-bold pl-3 ">
          <TimeAgo date={row.getValue()} formatter={formatter} />
        </div>
      );
    },
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <div className="flex gap-1 items-center justify-start">
        <CalendarClock size={16} />
        <DataTableColumnHeader
          className="text-xs "
          column={column}
          title="Güncellenme Zamanı"
        />
      </div>
    ),
    cell: (row) => {
      return (
        <div className="w-full flex  font-bold pl-2 ">
          <TimeAgo date={row.getValue()} formatter={formatter} />
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        deleteWorkingGroup={deleteWorkingGroup}
        updateWorkingGroup={updateWorkingGroup}
      />
    ),
    size: 20,
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];
