import { Button } from "@/components/ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";
import { DebouncedInput } from "../DebouncedInput";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

export function DataTableToolbar({ table, work_groups }) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const onChange = (event) => {
    table.getColumn("name")?.setFilterValue(event);
  };

  return (
    <div className="flex flex-wrap items-center justify-between">
      <div className="flex flex-1 flex-wrap items-center space-x-2 space-y-2 lg:space-y-0 lg:flex-nowrap justify-start">
        <DebouncedInput
          placeholder="Arşivelenen formlarda ara.."
          value={table.getColumn("name")?.getFilterValue() ?? ""}
          onChange={onChange}
          className="h-8 w-full lg:w-[250px]"
        />
        {table.getColumn("work_group") && (
          <DataTableFacetedFilter
            column={table.getColumn("work_group")}
            title="Çalışma Grubu"
            options={work_groups}
            className="w-full lg:w-auto"
          />
        )}
        {/* {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Durum"
            options={statuses}
            className="w-full lg:w-auto"
          />
        )} */}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3 w-full lg:w-auto"
          >
            Filtreyi Kaldır
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
