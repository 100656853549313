import { Printer } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { Button } from "./ui/button";

function QrCode({ qrCodeUrl, qrCode, formName }) {
  let didInit = false;
  const componentRef = useRef();
  const [fontSize, setFontSize] = useState();
  const [qrZoom, setQrZoom] = useState(100);

  const loadDataFromLocalStorage = () => {
    const localSettings = JSON.parse(
      localStorage.getItem("qrSettings") || "{}"
    );
    if (localSettings.font) setFontSize(localSettings.font);
    if (localSettings.qrZoom) setQrZoom(localSettings.qrZoom);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
          @page {
            size: auto;
            margin: 0;
          }
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;

            }
            header, footer {
              display: none;
            }
          }
        `,
  });

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      loadDataFromLocalStorage();
    }
  }, []);

  return (
    <div className="flex  flex-col gap-4 justify-center items-center w-full ">
      <div className="flex flex-row w-full">
        <div
          className="flex flex-row  justify-center  w-60mm h-30mm items-center "
          ref={componentRef}
        >
          <div className="flex flex-row pl-2 ">
            <div
              className=" text-center text-xs rotate-180 "
              style={{
                fontSize: "12px",
                writingMode: "vertical-lr",
              }}
            >
              {/**formun qr kısa kodu */}
              {qrCode}
            </div>
            <QRCode
              size={qrZoom}
              value={qrCodeUrl}
              className="rounded mx-auto"
            />
          </div>
          <div
            className=" text-left  flex items-center  whitespace-normal  justify-center   px-4 mt-2  overflow-hiden"
            style={{
              fontSize: `${fontSize}px`,
            }}
          >
            {/**formun adı */}
            {formName}
          </div>
        </div>
      </div>
      <Button
        type={"button"}
        onClick={handlePrint}
        variant="outline"
        className={" flex w-full gap-2"}
      >
        <Printer />
        Yazdır
      </Button>
    </div>
  );
}

export default QrCode;
