import NavigatorMenu from "@/components/NavigatorMenu";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import WorkGroupField from "@/components/WorkGroupField";
import { useAuth } from "@/context/authContext";
import { apiconfig } from "@/lib/apiconfig";
import { roles } from "@/lib/roleEnum";
import axios from "axios";
import {
  Delete,
  PenSquare,
  PlusCircle,
  RefreshCcw,
  Trash2,
  UserRoundCog,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, TableFooter } from "../../components/Table";
// Roller için bir obje oluşturun
const ROLES = {
  DEFAULTUSER: "KULLANICI",
  MANAGER: "SORUMLU",
  ADMIN: "YÖNETİCİ",
};

const UserManager = () => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [workGroups, setWorkGroups] = useState([]); // Başlangıçta bir çalışma grubu var
  const [emptyUserSchema] = useState({
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    email: '',
    phone_number: '',
    role: 'DEFAULTUSER',
  });
  const [newUser, setNewUser] = useState(emptyUserSchema);
  const [editUser, setEditUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const { user } = useAuth();
  if (user.role === roles.DEFAULTUSER || user.role === roles.MANAGER) {
    navigate("/completed-forms");
  }

  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  // Kullanıcı oluşturma dialogu açıldığında workGroups dizisini sıfırlamak için useEffect ekleyin
  useEffect(() => {
    if (createDialogOpen) {
      setWorkGroups([]); // WorkGroups dizisini boş olarak ayarlayın
    }
  }, [createDialogOpen]);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiconfig().url + "/api/user/list");
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Hata",
        description: "Veri çekme hatası",
        status: "error",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addWorkGroupField = () => {
    setWorkGroups((prevGroups) => [
      ...prevGroups,
      { id: prevGroups.length + 1 }
    ]);
  };
  
  const removeWorkGroupField = (id) => {
    setWorkGroups((prevGroups) => prevGroups.filter((group) => group.id !== id));
  };

  const setUserForEdit = (user) => {
    setEditUser(user);
    if (user.other_work_group_ids) {
      const workGroupFields = user.other_work_group_ids.map((id, index) => ({
        id: index + 1,
        value: id,
      }));
      setWorkGroups(workGroupFields);
    } else {
      setWorkGroups([]);
    }
  };
  
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [id]: value,
    }));
  };

  const handleEditInputChange = (e) => {
    const { id, value } = e.target;
    setEditUser((prevUser) => ({
      ...prevUser,
      [id]: value,
    }));
  };
  
  const handleSaveUser = async (e) => {
    e.preventDefault();
  
    // Dinamik olarak eklenen çalışma gruplarının değerlerini toplamak
    const workGroupIds = workGroups.map((group) => {
      return e.target[`work_group_id_${group.id}`].value;
    });
  
    const userToSave = {
      ...newUser,
      other_work_group_ids: workGroupIds,
    };
  
    try {
      const response = await axios.post(
        apiconfig().url + "/api/user/new",
        userToSave
      );
      console.log("Kullanıcı oluşturuldu:", response.data);
      fetchData();
      toast({
        title: "Başarılı",
        description: "Kullanıcı başarıyla oluşturuldu",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Hata",
        description: "Kullanıcı oluşturulamadı, tüm alanları doğru şekilde doldurduğunuzdan emin olun.",
        status: "error",
      });
    } finally {
      setNewUser(emptyUserSchema);
      setWorkGroups([]); // Çalışma grubu listesi sıfırlanıyor
    }
  };
  
  
  const handleUpdateUser = async (e) => {
    e.preventDefault();

    // Dinamik olarak eklenen çalışma gruplarının değerlerini toplamak
    const workGroupIds = workGroups.map((group) => {
      return e.target[`work_group_id_${group.id}`]?.value || '';
    }).filter(id => id !== '');

    // editUser objesine çalışma grubu ID'lerini ekleyin
    const userToUpdate = {
      ...editUser,
      other_work_group_ids: workGroupIds, // Çalışma grubu ID'lerini ekliyoruz
    };

    try {
      const response = await axios.post(
        apiconfig().url + "/api/user/update",
        userToUpdate
      );
      console.log("Kullanıcı güncellendi:", response.data);
      fetchData();
      toast({
        title: "Başarılı",
        description: "Kullanıcı başarıyla güncellendi",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Hata",
        description:
          "Kullanıcı güncellenemedi, tüm alanları doğru şekilde doldurduğunuzdan emin olun.",
        status: "error",
      });
    }
};


  const handleDeleteUser = async () => {
    try {
      await axios.post(apiconfig().url + "/api/user/delete", deleteUser);
      fetchData(); // Kullanıcı silindikten sonra tabloyu yenile
      setDeleteUser(null); // Delete dialog'u kapat
      toast({
        title: "Başarılı",
        description: "Kullanıcı başarıyla silindi",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Hata",
        description: "Kullanıcı silme hatası",
        status: "error",
      });
    }
  };

  const isWorkGroupFieldRequired = role => {
    return role !== "ADMIN";
  }

  const columns = [
    { text: "ID", column: "_id" },
    { text: "Kullanıcı adı", column: "username" },
    { text: "Kişi adı", column: "firstname" },
    { text: "Kişi soyadı", column: "lastname" },
    { text: "E-mail", column: "email" },
    { text: "Telefon numarası", column: "phone_number" },
    {
      text: "Çalışma grubu adı",
      column: "work_group_id",
      render: (row) => <>{row?.work_group?.name}</>,
    },
    {
      text: "Rol",
      column: "role",
      render: (row) => <>{ROLES[row.role] || "BELİRSİZ YETKİ"}</>,
    },
    {
      text: "Ayarlar",
      class: "p-2",
      render: (row) => (
        <span className="space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      className="p-2.5 h-8"
                      variant="outline"
                      onClick={() => setUserForEdit(row)}
                    >
                      <PenSquare size={18} />
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-2/3">
                    <DialogHeader>
                      <DialogTitle>Kullanıcıyı Düzenle</DialogTitle>
                      <DialogDescription>
                        Kullanıcı bilgilerini buradan düzenleyebilirsiniz.
                        İşlemi tamamlamak için kaydet'e tıklayın.
                      </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleUpdateUser}>
                      <div className="grid grid-cols-2 gap-4 py-4">
                        <div className="grid col-span-2 w-full items-center gap-1.5">
                          <Label htmlFor="username">Kullanıcı Adı</Label>
                          <Input
                            id="username"
                            value={editUser?.username || ''}
                            onChange={handleEditInputChange}
                            className="!lowercase"
                            required
                          />
                        </div>
                        <div className="grid w-full items-center gap-1.5">
                          <Label htmlFor="firstname">Adı</Label>
                          <Input
                            id="firstname"
                            value={editUser?.firstname || ''}
                            onChange={handleEditInputChange}
                            required
                          />
                        </div>
                        <div className="grid w-full items-center gap-1.5">
                          <Label htmlFor="lastname">Soyadı</Label>
                          <Input
                            id="lastname"
                            value={editUser?.lastname || ''}
                            onChange={handleEditInputChange}
                            required
                          />
                        </div>
                        <div className="grid w-full items-center gap-1.5">
                          <Label htmlFor="email">E-mail</Label>
                          <Input
                            id="email"
                            value={editUser?.email || ''}
                            onChange={handleEditInputChange}
                            required
                          />
                        </div>
                        <div className="grid w-full items-center gap-1.5">
                          <Label htmlFor="phone_number">Telefon Numarası</Label>
                          <Input
                            id="phone_number"
                            value={editUser?.phone_number || ''}
                            onChange={handleEditInputChange}
                            required
                          />
                        </div>
                        <div className="grid w-full items-center gap-1.5">
                          <Label htmlFor="role">Kullanıcı rolü</Label>
                          <Select
                            id="role"
                            value={editUser?.role || 'DEFAULTUSER'}
                            onValueChange={(value) => setEditUser((prevUser) => ({ ...prevUser, role: value }))}
                            required
                          >
                            <SelectTrigger className="!w-full space-x-2">
                              <SelectValue>{ROLES[editUser?.role] || 'DEFAULTUSER'}</SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              {Object.keys(ROLES).map((roleKey) => (
                                <SelectItem key={roleKey} value={roleKey}>{ROLES[roleKey]}</SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="grid w-full items-center gap-1.5">
                          <Label htmlFor="work_group_id">Çalışma Grubu</Label>
                          <WorkGroupField
                            name="work_group_id"
                            defaultValue={editUser?.work_group_id}
                            onValueChange={(value) => setEditUser((prevUser) => ({ ...prevUser, work_group_id: value }))}
                            required={isWorkGroupFieldRequired(editUser?.role)}
                            canBeLeftBlank={!isWorkGroupFieldRequired(editUser?.role)}
                          />
                        </div>
                        {workGroups.map((group, index) => (
                          <div key={group.id} className="grid w-full items-center gap-1.5 col-span-2">
                            <Label htmlFor={`work_group_id_${group.id}`}>{index + 1}. Ek Çalışma Grubu</Label>
                            <div className="flex space-x-4">
                              <WorkGroupField
                                name={`work_group_id_${group.id}`}
                                defaultValue={group.value}
                                onValueChange={(value) =>
                                  setEditUser((prevUser) => ({
                                    ...prevUser,
                                    [`work_group_id_${group.id}`]: value,
                                  }))
                                }
                              />
                              <Delete size={22} className="my-auto text-red-600 cursor-pointer" onClick={() => removeWorkGroupField(group.id)} />
                            </div>
                          </div>
                        ))}
                        <div className='grid w-full items-center gap-1.5 col-span-2'>
                          <Button type="button" onClick={addWorkGroupField} variant="ghost" className="w-full">
                            <PlusCircle size={20} className="mr-2" />
                            {workGroups.length === 0 ? 'Ek Çalışma Grubu Ekle' : 'Çalışma Grubu Ekle'}
                          </Button>
                        </div>
                      </div>
                      <DialogFooter>
                        <Button type="submit">Kaydet</Button>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              </TooltipTrigger>
              <TooltipContent side="bottom">
                <p>Düzenle</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      className="p-2.5 h-8"
                      variant="destructive"
                      onClick={() => setDeleteUser(row)}
                    >
                      <Trash2 size={19} />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Kullanıcıyı Sil</DialogTitle>
                      <DialogDescription>
                        Bu kullanıcıyı silmek istediğinizden emin misiniz? Bu
                        işlem geri alınamaz.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                      <DialogClose asChild>
                        <Button
                          variant="outline"
                          onClick={() => setDeleteUser(null)}
                        >
                          Hayır
                        </Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          type="submit"
                          variant="destructive"
                          onClick={handleDeleteUser}
                        >
                          Evet
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </TooltipTrigger>
              <TooltipContent side="bottom">
                <p>Sil</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </span>
      ),
    },
  ];

  return (
    <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">
          Kullanıcı yönetim paneli
        </h2>
        <NavigatorMenu show={true} />
      </div>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center space-x-2">
            <UserRoundCog size={21} strokeWidth={2} />
            <div>Kullanıcılar</div>
          </CardTitle>
          <CardDescription>
            kullanıcı oluşturabilir, düzenleyebilir veya silebilirsiniz.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table columns={columns} rows={users} loading={loading}>
            <TableFooter>
              <div className="float-left">
                <Button variant="outline" onClick={fetchData}>
                  <RefreshCcw size={18} className="me-2" />
                  Tabloyu Yenile
                </Button>
              </div>

              <Dialog open={createDialogOpen} onOpenChange={setCreateDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="outline">
                    <PlusCircle size={18} className="me-2" />
                    Kullanıcı Oluştur
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-2/3">
                  <DialogHeader>
                    <DialogTitle>Kullanıcı Oluştur</DialogTitle>
                    <DialogDescription>
                      Yeni bir kullanıcı oluşturun. İşlemi tamamlamak için kaydet'e tıklayın.
                    </DialogDescription>
                  </DialogHeader>
                  <form onSubmit={handleSaveUser}>
                    <div className="grid grid-cols-2 gap-4 py-4">
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="username">Kullanıcı Adı</Label>
                        <Input
                          id="username"
                          value={newUser.username}
                          onChange={handleInputChange}
                          className="!lowercase"
                          required
                        />
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="password">Kullanıcı Şifresi</Label>
                        <Input
                          id="password"
                          value={newUser.password}
                          onChange={handleInputChange}
                          className="!lowercase"
                          required
                        />
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="firstname">Adı</Label>
                        <Input
                          id="firstname"
                          value={newUser.firstname}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="lastname">Soyadı</Label>
                        <Input
                          id="lastname"
                          value={newUser.lastname}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="email">E-mail</Label>
                        <Input
                          id="email"
                          type="email"
                          value={newUser.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="phone_number">Telefon Numarası</Label>
                        <Input
                          id="phone_number"
                          type="number"
                          value={newUser.phone_number}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="role">Kullanıcı rolü</Label>
                        <Select
                          id="role"
                          value={newUser.role}
                          onValueChange={(value) => setNewUser((prevUser) => ({ ...prevUser, role: value }))}
                          required
                        >
                          <SelectTrigger className="!w-full space-x-2">
                            <SelectValue>{ROLES[newUser.role]}</SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            {Object.keys(ROLES).map((roleKey) => (
                              <SelectItem key={roleKey} value={roleKey}>{ROLES[roleKey]}</SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="grid w-full items-center gap-1.5">
                        <Label htmlFor="work_group_id">Ana Çalışma Grubu</Label>
                        <WorkGroupField
                          name="work_group_id"
                          defaultValue={newUser.work_group_id}
                          onValueChange={(value) => setNewUser((prevUser) => ({ ...prevUser, work_group_id: value }))}
                          required={isWorkGroupFieldRequired(newUser.role)}
                          canBeLeftBlank={!isWorkGroupFieldRequired(newUser.role)}
                        />
                      </div>
                      {workGroups.map((group, index) => (
                        <div key={group.id} className="grid w-full items-center gap-1.5 col-span-2">
                          <Label htmlFor={`work_group_id_${group.id}`}>{index + 1}. Ek Çalışma Grubu</Label>
                          <div className="flex space-x-4">
                            <WorkGroupField
                              name={`work_group_id_${group.id}`}
                              onValueChange={(value) =>
                                setNewUser((prevUser) => ({
                                  ...prevUser,
                                  [`work_group_id_${group.id}`]: value,
                                }))
                              }
                            />
                              <Delete size={22} className="my-auto text-red-600 cursor-pointer" onClick={() => removeWorkGroupField(group.id)} />
                          </div>
                        </div>
                      ))}

                      <div className='grid w-full items-center gap-1.5 col-span-2'>
                        <Button type="button" onClick={addWorkGroupField} variant="ghost" className="w-full">
                          <PlusCircle size={20} className="mr-2" />
                          {workGroups.length === 0 ? 'Ek Çalışma Grubu Ekle' : 'Çalışma Grubu Ekle'}
                        </Button>
                      </div>
                    </div>
                    <DialogFooter>
                      <Button type="submit">Kaydet</Button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
            </TableFooter>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserManager;
