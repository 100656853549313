import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Edit, Trash2 } from "lucide-react";
import { useState } from "react";

import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Label } from "../ui/label";

const DataTableRowActions = ({
  row,
  deleteWorkingGroup,
  updateWorkingGroup,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editGroupName, setEditGroupName] = useState(row.original.name);

  const handleDelete = () => {
    deleteWorkingGroup(row.original._id);
    setDeleteDialogOpen(false);
  };

  const handleEdit = () => {
    updateWorkingGroup({ id: row.original._id, name: editGroupName });
    setEditDialogOpen(false);
  };

  return (
    <div className="w-full flex gap-2 justify-center items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-red-600"
              size="sm"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <Trash2 size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Sil</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground"
              size="sm"
              onClick={() => setEditDialogOpen(true)}
            >
              <Edit size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Düzenle</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Çalışma Grubunu Sil</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Bu çalışma grubunu silmek istediğinizden emin misiniz? Bu işlem geri
            alınamaz.
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteDialogOpen(false)}
            >
              Hayır
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Evet, Sil
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={editDialogOpen} onOpenChange={setEditDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Çalışma Grubunu Düzenle</DialogTitle>
          </DialogHeader>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="name">Çalışma grubu ismi</Label>
            <Input
              type="text"
              id="name"
              placeholder="isim"
              value={editGroupName}
              onChange={(e) => setEditGroupName(e.target.value)}
              className="w-full"
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setEditDialogOpen(false)}>
              İptal
            </Button>
            <Button onClick={handleEdit}>Kaydet</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DataTableRowActions;
