import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import DataTable from "@/components/working-groups-data-table/data-table";
import { useAuth } from "@/context/authContext";
import { queryKeys } from "@/lib/queryKey.factory";
import { roles } from "@/lib/roleEnum";
import {
  createWorkingGroup,
  deleteWorkingGroup,
  getAllWorkingGroups,
  updateWorkingGroup,
} from "@/lib/workingGroups.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PlusCircle } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/ui/button";
import { getAllWorkingGroupsColumns } from "./working-groups-columns";
const breadcrumbItems = [
  { title: "Çalışma Grupları", link: "/dashboard/working-groups" },
];

export default function AllWorkingGroups() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");

  const { user } = useAuth();
  if (user.role === roles.DEFAULTUSER || user.role === roles.MANAGER) {
    navigate("/completed-forms");
  }

  const { data: allWorkingGroups, isFetching } = useQuery({
    queryKey: queryKeys.fetchWorkingGroups.all,
    queryFn: () => getAllWorkingGroups(),
    initialData: [],
  });

  const createWorkingGroupMutation = useMutation({
    mutationFn: createWorkingGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchWorkingGroups.all);
      setCreateDialogOpen(false);
      setNewGroupName("");
      toast({
        title: "Başarılı",
        description: "Çalışma grubu başarıyla oluşturuldu.",
      });
    },
    onError: () => {
      toast({
        title: "Hata!",
        description: "Çalışma grubu oluşturulurken bir hata oluştu.",
        variant: "destructive",
      });
    },
  });

  const deleteWorkingGroupMutation = useMutation({
    mutationFn: deleteWorkingGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchWorkingGroups.all);
      toast({
        title: "Başarılı",
        description: "Çalışma grubu başarıyla silindi.",
      });
    },
    onError: () => {
      toast({
        title: "Hata!",
        description: "Çalışma grubu silinirken bir hata oluştu.",
        variant: "destructive",
      });
    },
  });

  const updateWorkingGroupMutation = useMutation({
    mutationFn: updateWorkingGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchWorkingGroups.all);
      toast({
        title: "Başarılı",
        description: "Çalışma grubu başarıyla güncellendi.",
      });
    },
    onError: () => {
      toast({
        title: "Hata!",
        description: "Çalışma grubu güncellenirken bir hata oluştu.",
        variant: "destructive",
      });
    },
  });

  const columns = useMemo(
    () =>
      getAllWorkingGroupsColumns(
        deleteWorkingGroupMutation.mutate,
        updateWorkingGroupMutation.mutate
      ),
    [deleteWorkingGroupMutation.mutate, updateWorkingGroupMutation.mutate]
  );

  const handleCreateGroup = () => {
    createWorkingGroupMutation.mutate({ name: newGroupName });
  };

  function renderCreateDialog() {
    return (
      <Dialog
        open={createDialogOpen}
        onOpenChange={() => setCreateDialogOpen(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Çalışma Grubu Oluştur</DialogTitle>
          </DialogHeader>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="name">Çalışma grubu ismi</Label>
            <Input
              type="text"
              id="name"
              placeholder="isim"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              className="w-full"
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setCreateDialogOpen(false)}
            >
              İptal
            </Button>
            <Button
              onClick={handleCreateGroup}
              disabled={createWorkingGroupMutation.isLoading}
            >
              {createWorkingGroupMutation.isLoading
                ? "Oluşturuluyor..."
                : "Oluştur"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb items={breadcrumbItems} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Çalışma Grupları Listesi"}
            description={
              "Personel tarafından oluşturulan çalışma gruplarının yönetim ekranı"
            }
          />
          <div className="flex justify-center items-center">
            <Button
              variant="outline"
              onClick={() => setCreateDialogOpen(true)}
              className="space-x-2"
            >
              <PlusCircle size={19} />
              <div>Yeni Çalışma Grubu</div>
            </Button>
          </div>
        </div>
        <Separator />

        <div className="grid grid-cols-1 rounded-lg">
          {isFetching && <span className="px-5 py-5 ">Yükleniyor..</span>}
          {!isFetching && (
            <DataTable data={allWorkingGroups} columns={columns} />
          )}
        </div>

        {renderCreateDialog()}
      </div>
    </ScrollArea>
  );
}
