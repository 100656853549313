import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useEffect, useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { queryKeys } from "@/lib/queryKey.factory";
import { getAllWorkingGroups } from "@/lib/workingGroups.service";
import { useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
const DEFAULT_REACT_TABLE_COLUMN_WIDTH = 100;

const DataTable = ({ data, columns, user }) => {
  let didInit = false;
  const [groupList, setGroupList] = useState([]);

  const { data: workGroups, isFetching } = useQuery({
    queryKey: queryKeys.fetchWorkingGroups.all,
    queryFn: () => getAllWorkingGroups(),
    initialData: [],
    staleTime: 0,
  });

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,

      columnFilters,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const formatWorkingGroups = (workGroups) => {
    const data = workGroups.map((group) => ({
      value: group.name,
      label: group.name,
      icon: "",
    }));
    return data;
  };

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      const formatGroup = formatWorkingGroups(workGroups);
      setGroupList(formatGroup);
    }
  }, []);
  return (
    <div className="w-full flex flex-col ">
      <div className="w-full flex justify-start">
        <DataTableToolbar table={table} work_groups={groupList} />
      </div>

      <div className="rounded-md border overflow-hidden bg-white">
        <Table>
          <TableHeader className="bg-gray-200  ">
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id} className={""}>
                  {headerGroup.headers.map((header) => {
                    const styles =
                      header.getSize() !== DEFAULT_REACT_TABLE_COLUMN_WIDTH
                        ? { width: `${header.getSize()}px` }
                        : {};

                    return (
                      <TableHead
                        key={header.id}
                        style={styles}
                        className="font-medium"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className={"hover:bg-slate-100"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={""}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="">
                  Kayıt bulunamadı
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="h-0.5 w-full bg-gray-200" />
        <DataTablePagination table={table} />
      </div>
    </div>
  );
};

export default DataTable;
