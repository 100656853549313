import React, { useEffect, useState } from 'react';
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { CloudOff, Frown, ShieldX, Unplug } from 'lucide-react';

const UserManager = () => {

  return (
    <Card className="flex p-5">
      
      <Unplug size={73} strokeWidth={1.2} />
      <CardHeader className="p-2 ps-5">
        <CardTitle className="text-3xl flex items-center space-x-2">
          <div>Aradığınız sayfa bulunamadı!</div> 
          <Frown size={25} />
        </CardTitle>
        <CardDescription>
          Aradığınız sayfanın adresini yanlış girmiş olabilirsiniz, yukarıdaki navigasyon tuşlarını kullanarak istediğiniz sayfaya ulaşabilirsiniz.
        </CardDescription>
      </CardHeader>
    </Card>
  );
}

export default UserManager