import BreadCrumb from "@/components/BreadCrumb";
import {
  DraggableInput,
  DroppableForm,
  inputTypes,
} from "@/components/DraggableFields";
import { Heading } from "@/components/Heading";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { apiconfig } from "@/lib/apiconfig";
import axios from "axios";
import { Cog, Component, FilePlus, Save } from "lucide-react";
import { useCallback, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import {
  DocumentFields,
  GetDocumentFieldValues,
} from "../components/DocumentFields";
import { FormSettings } from "../components/FormSettings";
import NavigatorMenu from "../components/NavigatorMenu";
import WorkGroupField from "../components/WorkGroupField";

const CreateForm = () => {
  const navigate = useNavigate();
  const formSettingsRef = useRef();
  const [formFields, setFormFields] = useState([]);
  const { toast } = useToast();

  const handleDrop = useCallback((item) => {
    setFormFields((prevFields) => {
      const newId = prevFields.length + 1;
      return [...prevFields, { ...item, id: newId }];
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const target = event.target;
    console.log(formFields);
    const creator_user_id = localStorage.getItem("_id");

    const cleanFields = formFields.map(({ icon, ...field }) => ({
      ...field,
    }));

    const documentData = GetDocumentFieldValues(target);
    const formSettingsData = formSettingsRef.current.getSettingsValues();

    const newForm = {
      name: target.form_title.value,
      work_group_id: target.work_group_id.value,
      document_data: documentData,
      fields: cleanFields,
      settings: formSettingsData,
      creator_user_id: creator_user_id,
    };

    console.log("Kaydedilecek form:", newForm);

    try {
      const response = await axios.post(
        apiconfig().url + "/api/form/new",
        newForm
      );
      const savedForm = response.data.savedForm;
      console.log("Form kaydedildi:", response.data, savedForm);
      toast({
        title: <div className="truncate">
          {savedForm.name.length > 35 ? `${savedForm.name.substring(0, 35)}...` : savedForm.name}
        </div>,
        description: "Form başarıyla oluşturuldu.",
        autoClose: 6000,
      });
      navigate("/editable-forms");
      //navigate("/edit-form/" + savedForm._id);
    } catch (error) {
      console.error("Form kaydedilmedi:", error);
      toast({
        variant: "destructive",
        title: "Formunuz oluşturulurken bir hata aldık!",
        description: error.response.data.message,
        autoClose: 6000,
      });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex-1 space-y-4 p-4 pt-6 xl:p-8">
        <BreadCrumb items={[{ title: "Form Oluştur", link: "/create-form" }]} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Form oluştur"}
            description={
              "Personelinizin doldurması gereken formları oluşturabilirsiniz."
            }
          />
          <NavigatorMenu show={true} />
        </div>
        <Separator />
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 gap-4">
            <Card className="col-span-4 xl:col-span-1 w-full xl:row-span-2 h-fit sticky top-2">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <Component size={21} strokeWidth={2} />
                  <div>Form komponentleri</div>
                </CardTitle>
                <CardDescription>
                  Form komponentlerini sürükleyerek, formun satırlarını
                  oluşturabilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid space-y-1.5">
                {inputTypes.map((element, index) => (
                  <DraggableInput key={index} {...element} />
                ))}
              </CardContent>
            </Card>

            <Card className="col-span-4 xl:col-span-3">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <FilePlus size={21} strokeWidth={2} />
                  <div>Form oluşturma</div>
                </CardTitle>
                <CardDescription>
                  Form komponentlerini kullanarak sürükle-bırak ile form
                  oluşturabilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid space-y-2">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 border-b pb-4">
                  <div className="grid space-y-1">
                    <Label htmlFor="form_title">Form adı</Label>
                    <Input
                      type="text"
                      name="form_title"
                      id="form_title"
                      required
                    />
                  </div>
                  <div className="grid space-y-1">
                    <Label>Çalışma grubu</Label>
                    <WorkGroupField name="work_group_id" />
                  </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-5 gap-3 border-b pt-1 pb-4">
                  <DocumentFields />
                </div>
                <div className="relative flex py-3 items-center">
                  <div className="flex-grow border-t border-dashed border-gray-200"></div>
                  <span className="flex-shrink mx-4 text-gray-400 tracking-widest">
                    FORM DIZAYNI
                  </span>
                  <div className="flex-grow border-t border-dashed border-gray-200"></div>
                </div>
                <div>
                  <DroppableForm
                    onDrop={handleDrop}
                    formFields={formFields}
                    setFormFields={setFormFields}
                  />
                </div>
              </CardContent>
            </Card>

            <Card className="col-span-4 xl:col-span-3">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <Cog size={21} strokeWidth={2} />
                  <div>Form ayarları</div>
                </CardTitle>
                <CardDescription>
                  Form ayarlarında değişiklik yapabilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-1 lg:grid-cols-2 gap-5 text-slate-700">
                <FormSettings ref={formSettingsRef} />
              </CardContent>
            </Card>
          </div>
          <div className="w-full flex justify-end mt-4 font-medium">
            <Button variant="outline" className="w-fit space-x-2">
              <Save className="mr-1.5  size={19}" />
              Kaydet ve Barkod oluştur
            </Button>
          </div>
        </form>
      </div>
    </DndProvider>
  );
};

export default CreateForm;
