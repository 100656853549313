import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiconfig } from "@/lib/apiconfig";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import {
  ArchiveRestore,
  ArrowBigLeft,
  ArrowBigLeftDash,
  Check,
  ChevronLeft,
  CircleCheckBig,
  CircleOff,
  ClipboardCheck,
  Edit,
  FilePen,
  Inbox,
  MailCheck,
  Pen,
  Printer,
  Send,
  SendHorizonal,
  User,
  UserRoundCheck,
  View,
} from "lucide-react";

const CompletedForms = () => {
  const [userRole, setUserRole] = useState("");
  const [completedForms, setCompletedForms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("_id");
        const user = await axios.get(
          apiconfig().url + "/api/user/get?userId=" + userId
        );
        console.log(user.data);
        setUserRole(user.data.role);
        const forms = await axios.get(
          apiconfig().url + "/api/inspectform/list"
        );
        console.log(forms.data);
        setCompletedForms(forms.data);
      } catch (error) {
        console.error("Veri çekme hatası", error);
      }
    };

    fetchData();
  }, []);

  const renderStatusBar = (item) => {
    switch (item.status) {
      case "PENDING":
        return (
          <CardTitle className="bg-slate-500 text-white border-0 text-md  m-0 tracking-wider w-full text-center p-1 py-0.5 rounded-t-lg">
            FORM DOLDURULMUŞ
          </CardTitle>
        );
      case "TOAPPROVAL":
        return (
          <CardTitle className="bg-green-600 text-white border-0 text-md  m-0 tracking-wider w-full text-center p-1 py-0.5 rounded-t-lg">
            ONAY BEKLİYOR
          </CardTitle>
        );
      case "NEEDREVISE":
        return (
          <CardTitle className="bg-blue-400 text-white border-0 text-md  m-0 tracking-wider w-full text-center p-1 py-0.5 rounded-t-lg">
            REVİZE İSTİYOR
          </CardTitle>
        );
      case "SUCCESS":
        return (
          <CardTitle className="bg-green-500 text-white border-0 text-md  m-0 tracking-wider w-full text-center p-1 py-0.5 rounded-t-lg">
            ONAYLANDI
          </CardTitle>
        );
      default:
        return (
          <CardTitle className="bg-red-500 text-white border-0 text-md  m-0 tracking-wider w-full text-center p-1 py-0.5 rounded-t-lg">
            TAMAMLANMAMIŞ
          </CardTitle>
        );
    }
  };

  const renderCard = (completedForms, userRole) => {
    switch (userRole) {
      case "DEFAULTUSER":
        return completedForms.map((item, index) => {
          return (
            <Card className="flex flex-col justify-between">
              {renderStatusBar(item)}
              <CardHeader className="p-2 px-3 border-b">
                <CardTitle className="flex justify-between">
                  <div className="text-lg">{item.name}</div>
                </CardTitle>
              </CardHeader>
              <CardFooter className="grid grid-cols-3 gap-2 p-2 px-3">
                <Link to={"/inspect-form/" + item._id} className="block w-full">
                  <Button variant="outline" className="space-x-2 w-full">
                    <View size={22} />
                    <div>FORMU İNCELE</div>
                  </Button>
                </Link>
                <Link to={""} className="block w-full">
                  <Button variant="outline" className="space-x-2 w-full">
                    <Printer size={22} />
                    <div>FORMU YAZDIR</div>
                  </Button>
                </Link>
                <Link to={""} className="block w-full">
                  <Button className="space-x-2 w-full">
                    <ArchiveRestore size={22} />
                    <div>REVİZE TALEP ET</div>
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          );
        });
      case "MANAGER":
        return completedForms.map((item, index) => {
          if (item.status == "TOAPPROVAL")
            return (
              <Card className="flex flex-col justify-between">
                {renderStatusBar(item)}
                <CardHeader className="p-2 px-3 border-b">
                  <CardTitle className="flex justify-between">
                    <div className="text-lg">{item.name}</div>
                  </CardTitle>
                </CardHeader>
                <CardFooter className="grid grid-cols-2 gap-2 p-2 px-3">
                  <Link
                    to={"/inspect-form/" + item._id}
                    className="block w-full"
                  >
                    <Button variant="outline" className="space-x-2 w-full">
                      <View size={22} />
                      <div>FORMU İNCELE</div>
                    </Button>
                  </Link>
                  <Link to={""} className="block w-full">
                    <Button variant="outline" className="space-x-2 w-full">
                      <Printer size={22} />
                      <div>FORMU YAZDIR</div>
                    </Button>
                  </Link>
                  <Link to={""} className="block w-full">
                    <Button variant="destructive" className="space-x-2 w-full">
                      <CircleOff size={22} />
                      <div>FORMU REDDET</div>
                    </Button>
                  </Link>
                  <Link to={""} className="block w-full">
                    <Button
                      variant=""
                      className="space-x-2 bg-green-600 hover:bg-green-500 w-full"
                    >
                      <CircleCheckBig size={22} />
                      <div>FORMU ONAYLA</div>
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            );
        });
      case "ADMIN":
        return completedForms.map((item, index) => (
          <Card className="flex flex-col justify-between">
            {renderStatusBar(item)}
            <CardHeader className="p-2 px-3 border-b">
              <CardTitle className="flex justify-between">
                <div className="text-lg">{item.name}</div>

                {item.status == "NEEDREVISE" && (
                  <Link to={""} className="block">
                    <Button className="ps-3 space-x-1.5">
                      <ArchiveRestore size={22} />
                      <div>REVİZEYE GÖNDER</div>
                    </Button>
                  </Link>
                )}
              </CardTitle>
            </CardHeader>
            <CardContent className="p-4 px-5 space-y-1.5">
              <div className="flex items-center space-x-2">
                <UserRoundCheck size={20} />
                <div className="flex items-center space-x-2">
                  <div className="font-semibold">Samet akyalın</div>
                  <div className="text-sm disabled">FORMU DOLDURAN</div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <SendHorizonal size={20} />
                <div className="flex items-center space-x-2">
                  <div className="font-semibold">Ali yılmaz</div>
                  <div className="text-sm disabled">ONAYA GÖNDERİLEN</div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="grid grid-cols-3 gap-2 p-2 px-3 border-t">
              <Link to={"/inspect-form/" + item._id} className="block w-full">
                <Button variant="outline" className="space-x-2 w-full">
                  <View size={22} />
                  <div>FORMU İNCELE</div>
                </Button>
              </Link>
              <Link to={""} className="block w-full">
                <Button variant="outline" className="space-x-2 w-full">
                  <Printer size={22} />
                  <div>FORMU YAZDIR</div>
                </Button>
              </Link>
              <Link to={""} className="block w-full">
                <Button variant="destructive" className="space-x-2 w-full">
                  <CircleOff size={22} />
                  <div>HATALI FORM</div>
                </Button>
              </Link>
            </CardFooter>
          </Card>
        ));
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center space-x-2">
          <ClipboardCheck size={26} strokeWidth={2} />
          <div className="text-3xl">Doldurulan formlar paneli</div>
        </CardTitle>
        <CardDescription>
          Personel tarafından doldurulup gönderilen formlar bu panelde
          listelenir.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {renderCard(completedForms, userRole)}
      </CardContent>
    </Card>
  );
};

export default CompletedForms;
