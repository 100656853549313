import {
  DraggableInput,
  DroppableForm,
  inputTypes,
} from "@/components/DraggableFields";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Cog, Component, FilePen, SaveAll, ScanLine } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import GenerateQrCode from "./GenerateQrCode";

import { useToast } from "@/components/ui/use-toast";
import { apiconfig } from "@/lib/apiconfig";
import { queryKeys } from "@/lib/queryKey.factory"; // queryKeys import edin
import { useQueryClient } from "@tanstack/react-query"; // React Query'den useQueryClient import edin
import axios from "axios";
import {
  DocumentFields,
  GetDocumentFieldValues,
} from "../components/DocumentFields";
import { FormSettings } from "../components/FormSettings";
import WorkGroupField from "../components/WorkGroupField";

const EditForm = ({ form, onClose }) => {
  const navigate = useNavigate();
  const formSettingsRef = useRef();

  // Yerel form state'i oluşturun
  const [localForm, setLocalForm] = useState(form);
  const [formFields, setFormFields] = useState(form.fields || []);
  const [formDocumentData, setFormDocumentData] = useState(
    form.document_data || []
  );
  const [loading, setLoading] = useState(true); // Loading state
  const { toast } = useToast();

  const queryClient = useQueryClient(); // Query Client'ı alın

  // Prop olarak gelen form değiştiğinde yerel state'i güncelleyin
  useEffect(() => {
    if (form) {
      setLocalForm(form);
      setFormFields(form.fields || []);
      setFormDocumentData(form.document_data || []);
      setLoading(false); // Form yüklendiğinde loading'i false yap
    }
  }, [form]);

  const handleDrop = useCallback((item) => {
    setFormFields((prevFields) => {
      const newId = prevFields.length + 1;
      return [...prevFields, { ...item, id: newId }];
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const target = event.target;

    const cleanFields = formFields.map(({ icon, ...field }) => ({
      ...field,
    }));

    const documentData = GetDocumentFieldValues(target);
    const formSettingsData = formSettingsRef.current.getSettingsValues();

    const data = {
      _id: localForm._id,
      name: target.form_title.value,
      work_group_id: target.work_group_id.value,
      fields: cleanFields,
      document_data: documentData,
      settings: formSettingsData,
    };

    try {
      const response = await axios.post(
        `${apiconfig().url}/api/form/update`,
        data
      );

      const updatedForm = response.data.updatedForm;

      console.log("Form güncellendi:", response.data, updatedForm);
      toast({
        title: "Form güncellendi!",
        description: "Formunuz başarılı şekilde güncellendi.",
      });

      queryClient.setQueryData(queryKeys.fetchCompletedForms.all, (oldData) => {
        if (!oldData) return [updatedForm];

        // Tüm form item'ları arasında güncellenen formu bul ve sadece içeriklerini güncelle
        return oldData.map((formItem) => {
          if (formItem._id === updatedForm._id) {
            return {
              ...formItem, // Eski formun diğer alanlarını koru
              ...updatedForm, // Güncellenen alanları ekle
            };
          }
          return formItem; // Diğer formlar olduğu gibi kalır
        });
      });

      // onClose(); // Modalın otomatik kapanmasını kaldırın
    } catch (error) {
      console.error("Form güncellenemedi:", error);
      toast({
        variant: "destructive",
        title: "Formunuz güncellenirken bir hata aldık!",
        description:
          error.response?.data?.message || "Bilinmeyen bir hata oluştu.",
      });
    }
  };

  const handleBack = () => {
    navigate("/dashboard/editable-forms");
  };

  // Loading durumunda bir spinner ya da "Yükleniyor" mesajı gösterebilirsiniz
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full gap-2">
        <svg
          aria-hidden="true"
          class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="visually-hidden">Form yükleniyor...</span>
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex-1 space-y-4 pt-2 ">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 gap-4">
            <Card className="col-span-4 lg:col-span-1 w-full lg:row-span-2 h-fit sticky top-2">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <Component size={21} strokeWidth={2} />
                  <div>Form Komponentleri</div>
                </CardTitle>
                <CardDescription>
                  Form komponentlerini sürükleyerek, formun satırlarını
                  oluşturabilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid space-y-1.5">
                {inputTypes.map((element, index) => (
                  <DraggableInput key={index} {...element} />
                ))}
              </CardContent>
            </Card>

            <Card className="col-span-4 lg:col-span-3">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2">
                  <FilePen size={21} strokeWidth={2} />
                  <div>Form Güncelleme</div>
                </CardTitle>
                <CardDescription>
                  Form komponentlerini kullanarak sürükle-bırak ile formunuzu
                  değiştirebilirsiniz.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid space-y-2">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 border-b pb-4">
                  <div className="grid space-y-1">
                    <Label htmlFor="form_title">Form Adı</Label>
                    <Input
                      type="text"
                      name="form_title"
                      id="form_title"
                      value={localForm.name}
                      onChange={(e) =>
                        setLocalForm({ ...localForm, name: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="grid space-y-1">
                    <Label>Çalışma Grubu</Label>
                    {localForm.work_group_id && (
                      <WorkGroupField
                        defaultValue={localForm.work_group_id}
                        name="work_group_id"
                      />
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-5 gap-3 border-b pt-1 pb-4">
                  <DocumentFields filldata={formDocumentData} />
                </div>
                <div className="relative flex py-3 items-center">
                  <div className="flex-grow border-t border-dashed border-gray-200"></div>
                  <span className="flex-shrink mx-4 text-gray-400 tracking-widest">
                    FORM DİZAYNI
                  </span>
                  <div className="flex-grow border-t border-dashed border-gray-200"></div>
                </div>
                <div>
                  <DroppableForm
                    onDrop={handleDrop}
                    formFields={formFields}
                    setFormFields={setFormFields}
                  />
                </div>
              </CardContent>
            </Card>

            <div className="col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Card>
                <GenerateQrCode
                  formQrCode={localForm.qr_code}
                  name={localForm.name}
                >
                  <CardHeader>
                    <CardTitle className="flex items-center space-x-2">
                      <ScanLine size={21} strokeWidth={2} />
                      <div>Form Barkodu</div>
                    </CardTitle>
                    <CardDescription>
                      Form barkodunu yazdırıp, barkodu kameranıza okutarak formu
                      doldurabilirsiniz.
                    </CardDescription>
                  </CardHeader>
                </GenerateQrCode>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center space-x-2">
                    <Cog size={21} strokeWidth={2} />
                    <div>Form Ayarları</div>
                  </CardTitle>
                  <CardDescription>
                    Form ayarlarında değişiklik yapabilirsiniz.
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid grid-cols-1 gap-5 text-slate-700">
                  <FormSettings
                    ref={formSettingsRef}
                    filldata={localForm.settings}
                  />
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="w-full flex justify-end mt-4 space-x-2">
            <Button type="button" onClick={onClose} variant="outline">
              Kapat
            </Button>
            <Button type="submit" className="w-fit space-x-2">
              <SaveAll className="mr-1.5" size={20} />
              Formu Güncelle
            </Button>
          </div>
        </form>
      </div>
    </DndProvider>
  );
};

export default EditForm;
