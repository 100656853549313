import DataTableColumnHeader from "@/components/editable-forms-data-table/data-table-column-header";
import DataTableRowActions from "@/components/editable-forms-data-table/data-table-row-actions";
import { roles } from "@/lib/roleEnum";
import { convertStatus } from "@/lib/utils";
import { BookOpenText, CalendarClock, UserPlus } from "lucide-react";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import turkishStrings from "react-timeago/lib/language-strings/tr";
const formatter = buildFormatter(turkishStrings);

export const getAllFormsColumns = ({
  onPrintQr,
  onEdit,
  onFillForm,
  onDelete,
  user,
}) => [
  {
    accessorKey: "name",
    header: (props) => {
      return (
        <div className="flex gap-2  items-center justify-start text-normal ">
          <BookOpenText size={16} />
          <p>Form Adı</p>
        </div>
      );
    },
    cell: ({ row }) => {
      const { text, color } = convertStatus(row.original.status);
      return (
        <div className="w-64  flex justify-start items-center gap-2 text-[13px]  ">
          {/* <div className={`h-6 w-2  rounded-full ${color}`} /> */}
          <div className="">{row.original.name}</div>
        </div>
      );
    },
    // footer: (props) => {
    //   const totalForms = props.table.getRowModel().rows.length;
    //   return (
    //     <div className=" h-6">
    //       <span>Toplam :</span>
    //       <span className="font-bold">{totalForms}</span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "creator_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserPlus size={16} />
          <span>Formu Oluşturan</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const creator_user = row.original?.creator_user;
      const name = creator_user
        ? creator_user.firstname + " " + creator_user.lastname
        : "Bilinmiyor";
      return <div className="flex justify-center items-center ">{name}</div>;
    },
  },
  {
    accessorFn: (d) => (d.work_group ? d.work_group["name"] : "Belirtilmemiş"),
    id: "work_group",
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },

    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-start">
          <UserPlus size={16} />
          <span>Çalışma Grubu</span>
        </div>
      );
    },
    cell: ({ row }) => (
      <span className="">{row.original.work_group?.name}</span>
    ),
  },

  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-left "
        column={column}
        title="Oluşturma Zamanı"
      />
    ),
    cell: (row) => {
      return (
        <div className="w-full flex   font-bold pl-3 ">
          <TimeAgo date={row.getValue()} formatter={formatter} />
        </div>
      );
    },
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <div className="flex gap-1 items-center justify-start">
        <CalendarClock size={16} />
        <DataTableColumnHeader
          className=" "
          column={column}
          title="Güncellenme Zamanı"
        />
      </div>
    ),
    cell: (row) => {
      return (
        <div className="w-full flex   font-bold pl-2 ">
          <TimeAgo date={row.getValue()} formatter={formatter} />
        </div>
      );
    },
  },

  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        onPrintQr={onPrintQr}
        onEdit={onEdit}
        onFillForm={onFillForm}
        onDelete={user.role === roles.ADMIN && onDelete}
      />
    ),
    size: 20,
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];
