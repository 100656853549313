import { apiconfig } from "@/lib/apiconfig";
import axios from "axios";

const api = axios.create({
  baseURL: `${apiconfig().url}/api`,
});

export const validateToken = async () => {
  const tokenVerifyURL = "/api/token/verify";

  const token = localStorage.getItem("token");

  try {
    const response = await fetch(apiconfig().url + `${tokenVerifyURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    const data = await response.json();
    if (response.status) {
      const token = localStorage.getItem("token");
      api.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.common["Authorization"] = token;
    }

    return data.status;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};

export default api;
