import { LoaderCircle } from "lucide-react";

export const Loader = ({ label = "" }) => {
  return (
    <div className="flex flex-col justify-center items-center p-4 gap-2 ">
      <LoaderCircle className="animate-spin text-muted-foreground" />
      <p className=" text-muted-foreground">{label}</p>
    </div>
  );
};
