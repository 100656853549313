import { Button } from "@/components/ui/button";
import { ArrowLeft, Home } from "lucide-react";
import { memo } from "react";
import { Link } from "react-router-dom";

const NavigatorMenu = ({ show }) => {
  return (
    show && (
      <div className="space-x-2 h-fit">
        <Button
          variant="outline"
          className="rounded-lg gap-2"
          onClick={() => window.history.back()}
        >
          <ArrowLeft size={18} />
          <span>Geri</span>
        </Button>
        <Link to="/">
          <Button variant="outline" className="rounded-lg">
            <Home size={18} />
          </Button>
        </Link>
      </div>
    )
  );
};

export default memo(NavigatorMenu);
