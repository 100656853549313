// AllForms.jsx
import DataTable from "@/components/editable-forms-data-table/data-table";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/context/authContext";
import { usePagination } from "@/context/paginationContext";
import { formDeleteRequest, getAllForms } from "@/lib/editableForms.service";
import { queryKeys } from "@/lib/queryKey.factory";
import { roles } from "@/lib/roleEnum";
import { getUser } from "@/lib/user.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PlusCircle } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/ui/button";
import EditForm from "../EditForm";
import FillForm from "../FillForm";
import { getAllFormsColumns } from "./editable-forms-columns";

const breadcrumbItems = [
  { title: "Form Listesi", link: "/dashboard/editable-forms" },
];

export default function AllForms() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { pagination: page } = usePagination();
  const [selectedForm, setSelectedForm] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalFormDoldur, setOpenModalFormDoldur] = useState(false);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination durumu buraya taşındı
  const location = useLocation();

  // Kullanıcı rolü kontrolü
  if (user.role === roles.DEFAULTUSER || user.role === roles.MANAGER) {
    navigate("/completed-forms");
  }

  const queryClient = useQueryClient();

  // Tüm formları getirme
  const { data: allForms, isFetching } = useQuery({
    queryKey: queryKeys.fetchCompletedForms.all,
    queryFn: () => getAllForms(),
    initialData: [],

    refetchOnWindowFocus: false,
    // refetchOnReconnect: true,
  });

  // Kullanıcı verilerini getirme
  const { data: userData, isFetching: isFetchingUser } = useQuery({
    queryKey: queryKeys.fetchUser.single,
    queryFn: () => getUser(),
    initialData: [],
    keepPreviousData: true, // Önceki verileri koru
  });

  // Formu düzenleme
  const onEdit = useCallback((form) => {
    setSelectedForm(form);
    setOpenModal(true);
    console.log("Düzenlenecek form:", form);
  }, []);

  // Form doldurma görüntüleme
  const onFillForm = useCallback(async (form) => {
    // navigate(`/fill-form/${form.qr_code}`);

    setSelectedForm(form);
    setOpenModalFormDoldur(true);
    console.log("Doldurulacak Form: ", form);
  }, []);

  // Form silme
  const onDelete = useMutation({
    mutationFn: formDeleteRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchCompletedForms.all);
      toast({
        title: "Başarılı",
        description: "Form başarıyla silindi.",
      });
    },
    onError: () => {
      toast({
        title: "Hata!",
        description: "Form silinirken bir hata oluştu.",
        variant: "destructive",
      });
    },
  });

  // QR kodu yazdırma
  const onPrintQr = useCallback(() => {
    console.log("QR kodu yazdırma işlemi");
  }, []);

  // Tablo sütunlarını tanımlama
  const columns = useMemo(
    () =>
      getAllFormsColumns({
        user,
        onPrintQr,
        onEdit,
        onFillForm,
        onDelete: onDelete.mutate,
      }),
    [user, onPrintQr, onEdit, onFillForm, onDelete]
  );

  // useEffect ile context'teki pageIndex değişikliklerini izle
  useEffect(() => {
    // Context'teki pageIndex değiştiğinde local pagination'ı güncelle

    setPagination((prev) => ({
      ...prev,
      pageIndex: page.pageIndex,
      pageSize: page.pageSize, // Eğer pageSize da güncelleniyorsa
    }));
    // İsterseniz, pageIndex değiştiğinde başka işlemler de yapabilirsiniz
    console.log("Page index değişti:", page.pageIndex);
  }, [pagination.pageIndex, page.pageIndex]); // Sadece pageIndex veya pageSize değiştiğinde çalışır

  useEffect(() => {
    // Route değiştiğinde pagination'ı sıfırla

    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    console.log("Route değişti, pagination sıfırlandı:", location.pathname);
  }, [location.pathname]);

  return (
    <ScrollArea className="h-full ">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb items={breadcrumbItems} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Form Listesi"}
            description={
              "Personel tarafından oluşturulan formların yönetim ekranı"
            }
          />
          <div className="flex justify-center items-center">
            <Button variant="outline" asChild>
              <Link
                to="/create-form"
                className="flex justify-center items-center gap-2 font-medium "
              >
                <PlusCircle size={19} />
                Yeni Form
              </Link>
            </Button>
          </div>
        </div>
        <Separator />

        <div className="grid grid-cols-1 rounded-lg">
          {isFetching && <span className="px-5 py-5 ">Yükleniyor...</span>}
          {!isFetching && (
            <DataTable
              data={allForms}
              columns={columns}
              user={userData}
              pagination={pagination} // Pagination durumunu DataTable bileşenine gönderiyoruz
              setPagination={setPagination} // Sayfa değişikliklerini yakalamak için fonksiyonu da gönderiyoruz
            />
          )}
        </div>
      </div>

      {/* Modal Güncelle */}
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="flex flex-col max-w-screen h-screen overflow-auto !rounded-none  bg-[#f5f2f2]">
          <DialogTitle>Form Güncelle</DialogTitle>
          <DialogDescription>
            Oluşturulan formun satırlarını silebilir veya düzenleyebilirsiniz.
          </DialogDescription>
          <Separator />
          {selectedForm && (
            <EditForm form={selectedForm} onClose={() => setOpenModal(false)} />
          )}
        </DialogContent>
      </Dialog>

      {/* Modal Form Doldur */}
      <Dialog open={openModalFormDoldur} onOpenChange={setOpenModalFormDoldur}>
        <DialogContent className="flex flex-col max-w-screen h-screen overflow-auto !rounded-none  bg-[#f5f2f2] ">
          <div className="w-full flex flex-col gap-4">
            <DialogTitle>Form Doldur</DialogTitle>
            <DialogDescription>
              Oluşturulan formu inceleyip, form doldurma işlemi yapabilirsiniz!
            </DialogDescription>
            <Separator />
          </div>
          {selectedForm && <FillForm formQr={selectedForm.qr_code} />}
          <DialogFooter className={"flex  w-full justify-center  "}>
            <Button
              variant="outline"
              className="h-12 w-full sm:w-64"
              onClick={() => setOpenModalFormDoldur((prev) => !prev)}
            >
              Formu Kapat
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </ScrollArea>
  );
}
