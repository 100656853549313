export const statuses = [
  // {
  //   value: "PENDING",
  //   label: "Askıda Bekliyor",
  //   icon: "",
  // },
  {
    value: "SUCCESS",
    label: "Onaylandı",
    icon: "",
  },
  {
    value: "TOAPPROVAL",
    label: "Onay Bekliyor",
    icon: "",
  },

  {
    value: "NEEDREVISE",
    label: "Revize istiyor",
    icon: "",
  },
  {
    value: "INREVISE",
    label: "Revize Edilebilir",
    icon: "",
  },

  {
    value: "REJECTED",
    label: "Form Reddedildi",
    icon: "",
  },
];
