import React, { useEffect, useState } from "react";
import {
  TextInput,
  TextArea,
  SwitchInput,
} from "@/components/formfields/Inputs";
import {
  Calculator,
  Calendar as CalendarIcon,
  CircleCheckBig,
  Move,
  SaveAll,
  ScrollText,
  SquareCheckBig,
  Text,
  ToggleLeft,
  Trash2,
} from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { tr } from "date-fns/locale";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiconfig } from "@/lib/apiconfig";
import { CheckboxInput } from "../components/formfields/Inputs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";

const EditFilledForm = () => {
  const [form, setForm] = useState({});
  const [formDocumentData, setFormDocumentData] = useState({});
  const [responsibleUserID, setResponsibleUserID] = useState("");
  const [responsibleUsers, setResponsibleUsers] = useState([]);
  const { formId } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let filledform = await axios.get(
          apiconfig().url + "/api/inspectform/get?formId=" + formId
        );
        console.log(filledform);
        setForm(filledform.data);
        setFormDocumentData(filledform.data.filled_form.document_data);
        setResponsibleUserID(filledform.data.responsible_user_id);
      } catch (error) {
        console.error("Veri çekme hatası", error);
        navigate("/completed-forms");
      }
    };

    const fetchResponsibleUsers = async () => {
      try {
        const response = await axios.post(
          apiconfig().url + "/api/user/list/filter",
          {
            role: "MANAGER",
          }
        );
        console.log(response.data);
        setResponsibleUsers(response.data);
      } catch (error) {
        console.error("Veri çekme hatası users", error);
        navigate("/login");
      }
    };

    fetchData();
    fetchResponsibleUsers();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fillerUserId = localStorage.getItem("_id");

    const data = {
      _id: form._id,
      filled_fields: form.filled_fields,
      status: responsibleUserID || null ? "TOAPPROVAL" : "SUCCESS",
      filler_user_id: fillerUserId,
      responsible_user_id: responsibleUserID || null,
    };
    console.log(responsibleUserID || null);

    console.log("Kaydedilecek form:", data);

    try {
      const response = await axios.post(
        apiconfig().url + "/api/inspectform/update",
        data
      );
      const updatedFilledForm = response.data.updatedFilledForm;
      console.log("Form güncellendi:", response.data, updatedFilledForm);
      navigate('/completed-forms')
      toast({
        title: "Form güncellendi!",
        description:
          responsibleUserID || null
            ? "Formunuz, seçtiğiniz sorumlu kişisine değerlendirilmesi üzere gönderildi."
            : "Formunuz başarılı şekilde sistemde güncellendi.",
      });
    } catch (error) {
      console.error("Form güncellendi:", error);
      toast({
        variant: "destructive",
        title: "Formunuz güncellenirken bir hata aldık!",
        description: error.response.data.message,
      });
    }
  };

  const handleChangeField = (id, event) => {
    const newFields = form.filled_fields.map((field) => {
      console.log(field);
      if (field.id === id) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setForm({ ...form, filled_fields: newFields });
  };

  return (
    <Card>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-3">
        <div>
          <div className="grid grid-cols-8 border-b">
            <div className="border-e p-2">
              <img
                width="75"
                className="m-auto"
                src="https://upload.wikimedia.org/wikipedia/tr/a/a5/Recep_Tayyip_Erdo%C4%9Fan_%C3%9Cniversitesi_logo.jpg"
              />
            </div>
                <div className="grid grid-cols-1 col-span-7 xs:col-span-3 lg:col-span-5 text-center">
                  <div className="text-sm xs:text-md lg:text-lg xl:text-xl border-b font-semibold pt-1">
                DİŞ HEKİMLİĞİ FAKÜLTESİ
              </div>
              <div className="text-sm xs:text-md md:text-xl xl:text-3xl p-2 lg:p-4">
                {form.name}
              </div>
            </div>
            {formDocumentData && (
              <div className="grid grid-cols-2 col-span-8 text-xs sm:text-base xs:col-span-4 lg:col-span-2 border-s-0 xs:border-s border-t xs:border-t-0">
                <div className="border-e p-1 border-b text-slate-700">
                  Döküman Kodu
                </div>
                <div className="p-1 border-b text-slate-700">
                  {formDocumentData["document_code"]}
                </div>
                <div className="border-e p-1 border-b text-slate-700">
                  Revizyon No
                </div>
                <div className="p-1 border-b text-slate-700">
                  {formDocumentData["document_revision_number"]}
                </div>
                <div className="border-e p-1 border-b text-slate-700">
                  Yayın Tarihi
                </div>
                <div className="p-1 border-b text-slate-700">
                  {formDocumentData["document_release_date"]}
                </div>
                <div className="border-e p-1 border-b text-slate-700">
                  Revizyon Tarihi
                </div>
                <div className="p-1 border-b text-slate-700">
                  {formDocumentData["document_revision_date"]}
                </div>
                <div className="border-e p-1 text-slate-700">
                  Sayfa No
                </div>
                <div className="p-1 text-slate-700">
                  {formDocumentData["document_page_number"]}
                </div>
              </div>
            )}
          </div>
        </div>
        <CardContent className="grid grid-cols-7 py-2 pb-10">
          <div></div>
          <div className="col-span-7 xl:col-span-5 space-y-3">
            {form?.filled_fields?.map((field) => {
              switch (field.type) {
                case "form_description_textbox":
                  return (
                    <TextArea
                      key={field.id}
                      field={field}
                      readonly={true}
                      handleChange={handleChangeField}
                    />
                  );
                case "text":
                case "number":
                  return (
                    <TextInput
                      key={field.id}
                      field={field}
                      handleChange={handleChangeField}
                    />
                  );
                case "textbox":
                  return (
                    <TextArea
                      key={field.id}
                      field={field}
                      handleChange={handleChangeField}
                    />
                  );
                case "switch":
                  return (
                    <SwitchInput
                      key={field.id}
                      field={field}
                      handleChange={handleChangeField}
                    />
                  );
                case "checkbox":
                  return (
                    <CheckboxInput
                      key={field.id}
                      field={field}
                      handleChange={handleChangeField}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
          <div></div>
        </CardContent>
        <CardFooter className="flex justify-between pt-0">
          {form.filled_form?.settings?.responsible_user_required && (
            <div className="flex items-center space-x-2">
              <div className="">Form sorumlusu:</div>
              <Select
                onValueChange={(value) => setResponsibleUserID(value)}
                value={responsibleUserID}
                required={true}
              >
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="Form sorumlusu seçin.." />
                </SelectTrigger>
                <SelectContent>
                  {responsibleUsers.map((user) => (
                    <SelectItem key={user._id} value={user._id}>
                      {console.log(user)}
                      {user.firstname + " " + user.lastname}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          <Button>
            <SaveAll className="mr-1.5" size={20} />
            Formu Güncelle
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default EditFilledForm;
