import DataTableColumnHeader from "@/components/archived-forms-data-table/data-table-column-header";
import DataTableRowActions from "@/components/archived-forms-data-table/data-table-row-actions";
import { Badge } from "@/components/ui/badge";
import { convertStatus } from "@/lib/utils";
import { parseISO } from "date-fns";
import { BookOpenText, CalendarClock, UserCheck, UserPlus } from "lucide-react";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import turkishStrings from "react-timeago/lib/language-strings/tr";

const formatter = buildFormatter(turkishStrings);

const dateBetweenFilterFn = (rows, id, filterValue) => {
  const { from, to } = filterValue;
  return rows.filter((row) => {
    const rowDate = parseISO(row.values[id]);
    return rowDate >= from && rowDate <= to;
  });
};

export const getAllArchivedFormsColumns = ({ actions, onPreview, onPrint }) => [
  {
    accessorKey: "name",
    header: (props) => {
      return (
        <div className="flex  gap-2  items-center justify-start ">
          <BookOpenText size={16} />
          <span>Form Adı</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const { text, color } = convertStatus(row.original.status);
      return (
        <div className="w-64  flex justify-start items-center gap-2 text-[13px]  ">
          {/* <div className={`h-6 w-2  rounded-full ${color}`} /> */}
          <div className="">{row.original.name}</div>
        </div>
      );
    },
    // footer: (props) => {
    //   const totalForms = props.table.getRowModel().rows.length;
    //   return (
    //     <div className=" h-6">
    //       <span>Toplam :</span>
    //       <span className="font-bold">{totalForms}</span>
    //     </div>
    //   );
    // },
  },

  {
    accessorFn: (d) => d.filled_form.work_group["name"],
    id: "work_group",
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },

    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-start">
          <UserPlus size={16} />
          <span>Çalışma Grubu</span>
        </div>
      );
    },
    cell: ({ row }) => row.original.filled_form.work_group?.name,
  },
  {
    accessorKey: "filler_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserPlus size={16} />
          <span>Formu Dolduran</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const filler_user = row.original.filler_user;
      const filler_user_is_anon = row.original.filler_user_is_anon;
      const name = filler_user_is_anon
        ? "Anonim"
        : filler_user?.firstname + " " + filler_user?.lastname;
      return <div className="flex justify-center items-center">{name}</div>;
    },
  },
  {
    accessorKey: "responsible_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserCheck size={16} />
          <span>Form Sorumlusu</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const responsible_user = row.original?.responsible_user;
      if (!responsible_user) return "-";
      return responsible_user?.firstname + " " + responsible_user?.lastname;
    },
  },
  {
    accessorKey: "archiving_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserCheck size={16} />
          <span>Formu Arşivleyen</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const archiving_user = row.original?.archiving_user;
      if (!archiving_user) return "-";
      return (
        <Badge variant={"secondary"} className={"text-center text-green-800"}>
          {archiving_user?.firstname + " " + archiving_user?.lastname}
        </Badge>
      );
    },
  },
  // {
  //   accessorKey: "status",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader
  //       className="text-center"
  //       column={column}
  //       title="Durum"
  //     />
  //   ),
  //   cell: ({ row }) => {
  //     const status = statuses.find(
  //       (status) => status.value === row.getValue("status")
  //     );
  //     const { text, color } = convertStatus(row.original.status);
  //     if (!status) {
  //       return null;
  //     }

  //     return (
  //       <div className="flex w-full items-center">
  //         {status.icon && (
  //           <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
  //         )}
  //         <div className="w-full flex tems-center text-xs ">
  //           <Badge variant="outline" className={color}>
  //             {status.label}
  //           </Badge>
  //         </div>
  //       </div>
  //     );
  //   },
  //   filterFn: (row, id, value) => {
  //     return value.includes(row.getValue(id));
  //   },
  // },

  // {
  //   accessorKey: "createdAt",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader
  //       className="text-left "
  //       column={column}
  //       title="Oluşturma Zamanı"
  //     />
  //   ),
  //   cell: (row) => {
  //     return (
  //       <div className="w-full flex  text-xs font-bold pl-3 ">
  //         <TimeAgo date={row.getValue()} formatter={formatter} />
  //       </div>
  //     );
  //   },
  // },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <div className="flex gap-1 items-center justify-start">
        <CalendarClock size={16} />
        <DataTableColumnHeader column={column} title="Güncellenme Zamanı" />
      </div>
    ),
    // Filter: DateRangeColumnFilter,
    // filter: dateBetweenFilterFn,
    cell: (row) => {
      return (
        <div className="w-full flex   font-bold pl-2 ">
          <TimeAgo date={row.getValue()} formatter={formatter} />
        </div>
      );
    },
  },

  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={actions}
        onPreview={onPreview}
        onPrint={onPrint}
      />
    ),
    size: 20,
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];
