import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ExternalLink, FileIcon, Loader2, MoreHorizontal, Printer, Trash2, View, X } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiconfig } from "@/lib/apiconfig";
import { useToast } from "@/components/ui/use-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FileUp, Download, Eye } from "lucide-react";
import { cn } from "@/lib/utils";

const DataTableRowActions = ({ actions, row, onPreview, onPrint }) => {
  const isExternalForm = row.original?.isExternalForm;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [form, setForm] = useState(null);
  const { toast } = useToast();
  const [previewUrls, setPreviewUrls] = useState({});

  useEffect(() => {
    // Component unmount olduğunda URL'leri temizle
    return () => {
      Object.values(previewUrls).forEach(url => {
        URL.revokeObjectURL(url);
      });
    };
  }, []);

  const handleDeleteExternalForm = async () => {
    try {
      // Form ve ilişkili dosyaları sil
      const response = await axios.post(
        `${apiconfig().url}/api/form/delete-external-form`,
        { formId: row.original._id }
      );

      if (response.data.status) {
        toast({
          title: "Başarılı",
          description: "Form ve ilişkili dosyalar başarıyla silindi.",
        });
        // Tabloyu yenilemek için sayfayı yenile veya state'i güncelle
        window.location.reload();
      }
    } catch (error) {
      console.error("Silme hatası:", error);
      toast({
        variant: "destructive",
        title: "Hata",
        description: "Form silinirken bir hata oluştu.",
      });
    }
    setShowDeleteDialog(false);
  };

  const handlePreviewClick = async () => {
    setShowPreviewDialog(true);
    setLoading(true);
    try {
      // Form bilgilerini al
      const formResponse = await axios.get(`${apiconfig().url}/api/inspectform/get?formId=${row.original._id}`);
      setForm(formResponse.data);

      // Form dosyalarını al
      const filesResponse = await axios.get(`${apiconfig().url}/api/form/get-external-files?formId=${row.original._id}`);
      setFiles(filesResponse.data.files);
      
      // İlk dosyayı seç ve önizleme URL'sini oluştur
      if (filesResponse.data.files.length > 0) {
        const firstFile = filesResponse.data.files[0];
        if (firstFile.fileFormat.toLowerCase() === '.pdf') {
          const fileUrl = `${apiconfig().url}/external-form-uploads/${firstFile.fileName}`;
          setPreviewUrls(prev => ({...prev, [firstFile._id]: fileUrl}));
        }
        setSelectedFile(firstFile);
      }
    } catch (error) {
      console.error("Dosya yükleme hatası:", error);
      toast({
        variant: "destructive",
        title: "Hata",
        description: "Dosyalar yüklenirken bir hata oluştu.",
      });
    } finally {
      setLoading(false);
    }
  };

  const showPreview = (file) => {
    if (!file.fileFormat.toLowerCase().endsWith('.pdf')) {
      toast({
        title: "Önizleme Kullanılamaz",
        description: "Sadece PDF dosyaları önizlenebilir.",
        variant: "destructive",
      });
      return;
    }

    if (previewUrls[file._id]) {
      setSelectedFile(file);
    } else {
      const fileUrl = `${apiconfig().url}/external-form-uploads/${file.fileName}`;
      setPreviewUrls(prev => ({...prev, [file._id]: fileUrl}));
      setSelectedFile(file);
    }
  };

  if (isExternalForm) {
    return (
      <>
        <div className="w-full flex gap-2 justify-center items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="text-muted-foreground"
                  size="sm"
                  onClick={handlePreviewClick}
                >
                  <View size={19} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-xs">Form Dosyalarını Önizle</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="text-destructive"
                  size="sm"
                  onClick={() => setShowDeleteDialog(true)}
                >
                  <Trash2 size={19} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-xs">Formu ve Dosyaları Sil</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* Silme Dialog'u */}
        <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Formu ve Dosyaları Sil</AlertDialogTitle>
              <AlertDialogDescription>
                Bu form ve ilişkili tüm dosyalar kalıcı olarak silinecektir. Bu işlem geri alınamaz.
                Devam etmek istiyor musunuz?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>İptal</AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDeleteExternalForm}
                className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              >
                Sil
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Önizleme Dialog'u */}
        <Dialog open={showPreviewDialog} onOpenChange={setShowPreviewDialog}>
          <DialogContent className="max-w-7xl h-[90vh]">
            <DialogHeader>
              <DialogTitle>{form?.name || 'Form'} Dosyaları İnceleme</DialogTitle>
            </DialogHeader>

            {loading ? (
              <div className="flex-1 flex items-center justify-center">
                <Loader2 className="h-8 w-8 animate-spin" />
              </div>
            ) : (
              <div className="flex h-[79vh] gap-4">
                {/* Sol Panel - Dosya Listesi */}
                <div className="w-1/3 border rounded-lg p-4">
                  <div className="font-medium mb-4 flex items-center gap-2">
                    <FileUp size={16} />
                    <span>Dosyalar ({files.length})</span>
                  </div>
                  <ScrollArea className="h-full">
                    {files.map((file) => (
                      <div 
                        key={file._id} 
                        className={cn(
                          "p-2 rounded-md flex items-center gap-2 hover:bg-muted cursor-pointer mb-2",
                          selectedFile?._id === file._id && "bg-muted"
                        )}
                        onClick={() => showPreview(file)}
                      >
                        <FileIcon className="w-4 h-4 text-muted-foreground" />
                        <div className="flex-1 min-w-0">
                          <div className="text-sm truncate" title={file.originalName}>
                            {file.originalName}
                          </div>
                          <div className="text-xs text-muted-foreground">
                            {(file.fileSize / 1024 / 1024).toFixed(2)} MB
                          </div>
                        </div>
                          <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(`${apiconfig().url}/external-form-uploads/${file.fileName}`, '_blank')
                            }}
                          >
                            <ExternalLink className="w-4 h-4" />
                          </Button>
                      </div>
                    ))}
                  </ScrollArea>
                </div>

                {/* Sağ Panel - Önizleme */}
                <div className="flex-1 rounded-md">
                  {selectedFile ? (
                    selectedFile.fileFormat.toLowerCase() === '.pdf' ? (
                      <object
                        data={previewUrls[selectedFile._id]}
                        type="application/pdf"
                        className="w-full h-full rounded-md"
                      >
                        <p>PDF görüntüleyici desteklenmiyor</p>
                      </object>
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <div className="text-center">
                          <FileIcon className="h-12 w-12 mx-auto mb-3" />
                          <p className="text-sm text-muted-foreground mb-3">Bu dosya türü önizlenemez</p>
                          <Button 
                            size="sm"
                            onClick={() => window.open(`${apiconfig().url}/external-form-uploads/${selectedFile.fileName}`, '_blank')}
                          >
                            Dosyayı İndir
                          </Button>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="flex items-center justify-center h-full text-muted-foreground">
                      Önizleme için bir dosya seçin
                    </div>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }

  const isEdit =
    row.original.status === "INREVISE" || row.original.status === "REJECTED";
  const isNeedRevize = row.original.status === "NEEDREVISE";

  const isArchiveable = row.original.status === "SUCCESS";

  if (!actions || actions.length === 0) {
    return null; // Eğer actions undefined veya boşsa, hiçbir şey render etme
  }

  return (
    <div className="w-full flex gap-2 justify-center items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onPreview(row.original)}
            >
              <View size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu İncele</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onPrint(row.original)}
            >
              <Printer size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu Yazdır</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size="sm"
            variant="outline"
            className="text-muted-foreground "
          >
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {actions.map((action, index) => {
            if (action.label === "Düzenle" && !isEdit) {
              return null;
            }
            if (action.label === "Revize Talep" && isEdit) {
              return null;
            }
            if (action.label === "Revize Talep" && isNeedRevize) {
              return null;
            }
            if (action.label === "Revize Talep" && isEdit) {
              return null;
            }
            if (action.label === "Revizeye Gönder" && !isNeedRevize) {
              return null;
            }

            if (action.label === "Formu Arşivle" && !isArchiveable) {
              return null;
            }

            /* formu anonim kişi doldurdu ise, actions menüsünde sadece formu arşivle ve sil butonunu göster. */
            if (
              row.original.filler_user_is_anon &&
              (action.label != "Formu Arşivle" && action.label != "Formu Sil") &&
              isArchiveable
            ) {
              return null;
            }

            return (
              <DropdownMenuItem
                key={index}
                onClick={() => action.action(row.original)}
              >
                {action.label}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default DataTableRowActions;
