import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/authContext";
import { DASHBOARD_SIDEBAR_LINKS } from "@/lib/navigation";
import classNames from "classnames";
import { ChevronLeft, Layers, LogOut } from "lucide-react";
import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/kurum_log.png";

const linkClasses =
  "flex group items-center  tracking-wide gap-3 px-3 leading-[42px] text-[.875rem]  hover:bg-gray-50  hover:no-underline active:bg-gray-50 ";

const sidebarClasses =
  "flex h-screen flex-col bg-white z-50 text-gray-700 sticky top-0  duration-300 border-r-2 border-gray-200 ";

export default function Sidebar() {
  const [open, setOpen] = useState(true);
  const { user } = useAuth();

  const username = useMemo(() => {
    const user = localStorage.getItem("username");
    return user;
  }, []);

  return (
    <aside className={classNames(open ? "w-80" : "w-[48px]", sidebarClasses)}>
      <div
        className={`absolute 
       right-0 translate-x-4 
       top-0  rounded-full z-50 flex justify-center items-center duration-300  `}
      >
        <Button
          onClick={() => setOpen((prev) => !prev)}
          size="icon"
          variant="outline"
          className="absolute right-0  top-1/2 mt-2  w-8 h-8   bg-white rounded-full md:inline-flex"
        >
          <ChevronLeft
            className={`h-5 w-5 text-black ${open ? "" : "rotate-180"}`}
          />
        </Button>
      </div>
      <div className="flex  flex-col justify-start px-3 items-start pt-12 h-72  relative">
        <Link
          to="/"
          className=" flex text-left items-center gap-4   overflow-hidden whitespace-pre"
        >
          <span>
            <Layers />
          </span>
          {open && (
            <div className="flex flex-col text-center">
              <strong className="h5 text-xl mb-0 tracking-wider">
                BiGateWare Forms
              </strong>
              <span className="text-muted-foreground text-uppercase font-light tracking-widest">
                Dijital Form Yönetimi
              </span>
            </div>
          )}
        </Link>
        {open && (
          <div className=" w-full flex flex-col  gap-2 my-auto  p-2  text-center rounded-xl  ">
            <img
              src={logo}
              className={`${
                open ? "h-20  " : "h-5 w-5"
              }   object-contain object-center bg-[#068A92] p-3 rounded-xl`}
            />

            <span className="font-normal text-muted-foreground text-sm tracking-widest ">
              Diş Hekimliği Fakültesi
            </span>
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col ">
        {DASHBOARD_SIDEBAR_LINKS.map((item, i) => {
          return (
            <SidebarLink key={item.key} item={item} index={i} open={open} />
          );
        })}
      </div>
      <div className="flex flex-col border-t border-neutral-700 gap-0.5 relative">
        {/* {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item, i) => (
          <SidebarLink key={item.key} item={item} index={i} open={open} />
        ))} */}

        {/* <div
          className={classNames(
            "text-left px-3 font-light text-xs text-sky-400"
          )}
        >
          {open && username}
        </div> */}

        <Link
          to={"/logout"}
          variant="outline"
          className={classNames(linkClasses, "cursor-pointer  text-gray-700")}
        >
          <span>
            <LogOut />
          </span>
          <h2
            className={` whitespace-pre duration-200 font-medium ${
              !open && "opacity-0 translate-x-28 overflow-hidden"
            }`}
          >
            {"Çıkış Yap"}
          </h2>
          <h2
            className={`${
              open && "hidden"
            } absolute left-48 bg-gray-600 z-50  whitespace-pre text-white font-medium rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
          >
            {"Çıkış Yap"}
          </h2>
        </Link>
      </div>
    </aside>
  );
}

function SidebarLink({ item, index, open }) {
  const { pathname } = useLocation();

  return (
    <>
      <Link
        to={item.path}
        className={classNames(
          pathname === item.path
            ? "bg-gray-200 text-gray-700 font-medium   "
            : "text-gray-400  font-medium    border-transparent",

          linkClasses
        )}
      >
        <div className={"flex justify-center items-center"}>{item.icon}</div>

        <h2
          style={{ transitionDelay: `${index + 1}00ms` }}
          className={` whitespace-pre duration-100 ${
            !open && "opacity-0 translate-x-28 overflow-hidden"
          }`}
        >
          {item.label}
        </h2>
        <h2
          className={`${
            open && "hidden"
          } absolute left-48 bg-gray-600 shadow-md z-50  whitespace-pre text-white font-medium rounded-sm drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2  group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
        >
          {item?.label}
        </h2>
      </Link>
    </>
  );
}
