import BreadCrumb from "@/components/BreadCrumb";
import { Heading } from "@/components/Heading";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { apiconfig } from "@/lib/apiconfig";
import axios from "axios";
import { FilePlus, FileUp, Save, Upload, Eye, Trash2, X, Maximize2, ScanEye, Loader2 } from "lucide-react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import WorkGroupField from "../components/WorkGroupField";
import NavigatorMenu from "../components/NavigatorMenu";
import { getUser } from "@/lib/user.service";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";

const UploadForm = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [user, setUser] = useState(null);
  const { toast } = useToast();
  const [dragActive, setDragActive] = useState(false);
  const [previewUrls, setPreviewUrls] = useState({});
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [activeFileName, setActiveFileName] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser();
        setUser(userData);
      } catch (error) {
        console.error("Kullanıcı bilgileri alınamadı:", error);
      }
    };
    fetchUser();
  }, []);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const maxSize = 10 * 1024 * 1024; // 10MB

    const validFiles = newFiles.filter(file => {
      const isValidType = ['.pdf', '.doc', '.docx', '.csv'].some(ext => 
        file.name.toLowerCase().endsWith(ext)
      );
      
      if (!isValidType) {
        toast({
          variant: "destructive",
          title: "Geçersiz dosya tipi",
          description: "Sadece PDF, DOC ve CSV dosyaları yükleyebilirsiniz.",
        });
        return false;
      }

      if (file.size > maxSize) {
        toast({
          variant: "destructive",
          title: "Dosya çok büyük",
          description: "Lütfen 10MB'dan küçük dosyalar seçin.",
        });
        return false;
      }

      return true;
    });

    setFiles(prevFiles => {
      const updatedFiles = [...prevFiles, ...validFiles];
      if (validFiles.length > 0) {
        const lastFile = validFiles[validFiles.length - 1];
        if (lastFile.name.toLowerCase().endsWith('.pdf')) {
          const fileUrl = URL.createObjectURL(lastFile);
          setPreviewUrls(prev => ({...prev, [lastFile.name]: fileUrl}));
          setSelectedPreview(fileUrl);
          setActiveFileName(lastFile.name);
        }
      }
      return updatedFiles;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const target = event.target;
    const formName = target.form_name.value;
    const workGroupId = target.work_group_id.value;

    if (files.length === 0 || !formName || !workGroupId) {
      toast({
        variant: "destructive",
        title: "Lütfen tüm alanları doldurun!",
        description: "Form adı, çalışma grubu ve en az bir dosya gereklidir.",
      });
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    files.forEach(file => {
      formData.append("files", file);
    });
    formData.append("formName", formName);
    formData.append("workGroupId", workGroupId);
    formData.append("uploaderId", user?._id);

    try {
      const response = await axios.post(
        apiconfig().url + "/api/form/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Form yüklendi:", response.data);
      toast({
        title: "Form başarıyla yüklendi!",
        description: "PDF form sisteme kaydedildi.",
      });
      navigate("/completed-forms");
    } catch (error) {
      console.error("Form yüklenemedi:", error);
      toast({
        variant: "destructive",
        title: "Form yüklenirken bir hata oluştu!",
        description: error.response?.data?.message || "Bilinmeyen bir hata oluştu.",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const newFiles = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files: newFiles } });
  };

  const removeFile = (fileName) => {
    setFiles(files.filter(file => file.name !== fileName));
    
    if (previewUrls[fileName] === selectedPreview) {
      setSelectedPreview(null);
      setActiveFileName(null);
    }
    
    if (previewUrls[fileName]) {
      URL.revokeObjectURL(previewUrls[fileName]);
      setPreviewUrls(prev => {
        const newUrls = {...prev};
        delete newUrls[fileName];
        return newUrls;
      });
    }
    toast({
      title: "Dosya kaldırıldı.",
      description: `${fileName.length > 40 ? fileName.substring(0, 40) + '...' : fileName}`,
    });
  };

  // Component unmount olduğunda tüm URL'leri temizle
  useEffect(() => {
    return () => {
      Object.values(previewUrls).forEach(url => {
        URL.revokeObjectURL(url);
      });
    };
  }, []);

  const showPreview = (fileName) => {
    if (!fileName.toLowerCase().endsWith('.pdf')) {
      toast({
        title: "Önizleme Kullanılamaz",
        description: "Sadece PDF dosyaları önizlenebilir.",
        variant: "destructive",
      });
      return;
    }

    if (previewUrls[fileName]) {
      setSelectedPreview(previewUrls[fileName]);
      setActiveFileName(fileName);
    } else {
      const file = files.find(f => f.name === fileName);
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrls(prev => ({...prev, [fileName]: fileUrl}));
        setSelectedPreview(fileUrl);
        setActiveFileName(fileName);
      }
    }
  };

  return (
    <div className="flex-1 space-y-4 p-4 pt-6 xl:p-8">
      <BreadCrumb items={[{ title: "PDF Form Yükle", link: "/upload-form" }]} />
      <div className="flex items-center justify-between space-y-2">
        <Heading
          title={"PDF Form Yükle"}
          description={"Sisteme bir PDF formu yükleyin."}
        />
        <NavigatorMenu show={true} />
      </div>
      <Separator />
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="flex items-center space-x-2">
                <FileUp size={21} strokeWidth={2} />
                <div>PDF Form Yükleme</div>
              </CardTitle>
              <CardDescription>
                Lütfen yüklemek istediğiniz PDF formunu ve gerekli bilgileri girin.
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="grid gap-6">
                <div className="grid grid-cols-1 gap-4">
                  <div className="col-span-1 space-y-1">
                    <Label htmlFor="form_name">Form adı</Label>
                    <Input
                      type="text"
                      id="form_name"
                      required
                    />
                  </div>
                  <div className="col-span-1 space-y-1">
                    <Label>Çalışma grubu</Label>
                    <WorkGroupField name="work_group_id" />
                  </div>
                </div>
                <div className="space-y-1">
                  <Label htmlFor="pdf_file" className="space-x-2">
                    <span>Form Dosyası Yükle</span>
                    <span className="text-xs text-muted-foreground">(PDF, DOC, DOCX, CSV)</span>
                  </Label>
                  <div
                    className={cn(
                      "border border-dashed rounded-lg p-5 text-center cursor-pointer",
                      "hover:bg-muted/50 transition-colors duration-200",
                      dragActive ? "border-primary bg-muted/50" : "border-muted-foreground/25",
                      "relative"
                    )}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById("pdf_file").click()}
                  >
                    <input
                      type="file"
                      id="pdf_file"
                      accept=".pdf,.doc,.docx,.csv"
                      onChange={handleFileChange}
                      multiple
                      className="hidden"
                    />
                    <div className="flex flex-col items-center gap-2">
                      <FilePlus className="w-7 h-7 text-muted-foreground" />
                      <div className="text-lg font-medium">
                        Yüklemek için dosya seçin
                      </div>
                      <div className="text-sm text-muted-foreground">
                        veya dosyaları buraya sürükleyin
                      </div>
                    </div>
                  </div>
                </div>
                {files.length > 0 && (
                  <div className="space-y-2">
                    <Label>Yüklenen Dosyalar ({files.length})</Label>
                    <div className="border rounded-lg">
                      <div className="max-h-[260px] overflow-y-auto divide-y">
                        {files.map((file, index) => (
                          <div 
                            key={index} 
                            className="px-3 py-2 flex items-center justify-between hover:bg-muted/50"
                          >
                            <div className="flex items-center gap-2">
                              <FileUp className="w-4 h-4 text-muted-foreground flex-shrink-0" />
                              <span className="text-sm font-medium truncate max-w-[400px]" title={file.name}>
                                {file.name}
                              </span>
                              <span className="text-xs text-muted-foreground whitespace-nowrap">
                                ({(file.size / 1024 / 1024).toFixed(2)} MB)
                              </span>
                            </div>
                            <div className="flex items-center gap-2 flex-shrink-0">
                              <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => showPreview(file.name)}
                              >
                                <Eye className="w-4 h-4" />
                              </Button>
                              <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => removeFile(file.name)}
                              >
                                <Trash2 className="w-4 h-4 text-destructive" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
          
          <Card className="h-full">
            <CardHeader className="pt-4 pb-3">
              <div className="grid grid-cols-2">
              <CardTitle className="flex items-center space-x-2">
                <ScanEye size={21} strokeWidth={2} />
                <div>Önizleme</div>
              </CardTitle>
              
              <div className="text-right">
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        if (selectedPreview && activeFileName) {
                          setIsPreviewModalOpen(true);
                        }
                      }}
                      className={cn(
                        "w-32 space-x-2",
                        selectedPreview ? "cursor-pointer"
                        : "opacity-50 cursor-not-allowed"
                      )}
                    >
                      <Maximize2 className="w-4 h-4" />
                      <span>Tam Ekran</span>
                    </Button>
                  </div>
              </div>
              <CardDescription>
                Yüklediğiniz PDF formları önizleyebilirsiniz. Sadece PDF dosyaları önizlenebilir.
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
                <div className="rounded-s h-[60vh] overflow-hidden">
                  {activeFileName && (
                    <div className="pb-1 font-semibold">
                      {activeFileName}
                    </div>
                  )}
                  {selectedPreview ? (
                    <object
                      data={selectedPreview + "#navpanes=0&toolbar=0&view=FitH&scrollbar=0&pagemode=none&zoom=100"}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                      className="rounded-s"
                    >
                      <param name="view" value="FitH" />
                      <param name="scrollbar" value="0" />
                      <param name="toolbar" value="0" />
                      <param name="navpanes" value="0" />
                      <embed
                        src={selectedPreview}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                        className="rounded-lg"
                        toolbar="0"
                        navpanes="0"
                        scrollbar="0"
                        view="FitH"
                      />
                      <p>Dosya önizlemesi kullanılamıyor</p>
                    </object>
                  ) : (
                    <div className="flex border rounded-lg bg-muted/10 items-center justify-center h-full text-muted-foreground">
                      <div className="flex flex-col items-center gap-2">
                        <ScanEye className="w-10 h-10" />
                        <span>Önizleme için listeden bir dosya seçin</span>
                      </div>
                    </div>
                  )}
                </div>
            </CardContent>
          </Card>
        </div>
        <div className="w-full flex justify-end mt-4 font-medium">
          <Button 
            type="submit" 
            className="w-fit space-x-2"
            disabled={isUploading}
          >
            {isUploading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Yükleniyor...</span>
              </>
            ) : (
              <>
                <Upload className="mr-1.5 size={19}" />
                <span>PDF Formu Yükle</span>
              </>
            )}
          </Button>
        </div>
      </form>

      {/* Tam Ekran Modal */}
      <Dialog open={isPreviewModalOpen} onOpenChange={setIsPreviewModalOpen}>
        <DialogContent className="max-w-screen-xl h-[90vh]">
          <DialogHeader>
            <div className="flex items-center space-x-2">
              <Eye size={21} strokeWidth={2} />
              <div className="font-medium truncate">
                {activeFileName || 'PDF Formu Önizleme'}
              </div>
            </div>
          </DialogHeader>
          <div className="h-[80vh]">
            <object
              data={selectedPreview}
              type="application/pdf"
              width="100%"
              height="100%"
              className="rounded-lg"
            >
              <embed
                src={selectedPreview}
                type="application/pdf"
                width="100%"
                height="100%"
                className="rounded-lg"
              />
              <p>Dosya önizlemesi kullanılamıyor</p>
            </object>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadForm;
