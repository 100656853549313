import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { apiconfig } from "@/lib/apiconfig";
import axios from "axios";
import { FilePen } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";

const EditableForms = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiconfig().url + "/api/form/list");
        console.log(response);
        setItems(response.data);
      } catch (error) {
        console.error("Veri çekme hatası", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center space-x-2">
          <FilePen size={26} strokeWidth={2} />
          <div className="text-3xl">Form düzenleme paneli</div>
        </CardTitle>
        <CardDescription>
          Sorumlular tarafından oluşturulan formları bu panelde listeleyebilir,
          düzenleyebilirsiniz ve gerekirse doldurabilirsiniz.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {items.map((item, index) => (
          <Card className="flex flex-col justify-between">
            <CardHeader className="p-2 px-3">
              <CardTitle className="text-lg">{item.name}</CardTitle>
            </CardHeader>
            <CardFooter className="grid grid-cols-2 gap-2 p-2 pt-0 px-3">
              <Link to={"/edit-form/" + item._id} className="block w-full">
                <Button variant="outline" className="w-full">
                  DÜZENLE
                </Button>
              </Link>
              <Link to={"/fill-form/" + item._id} className="block w-full">
                <Button className="w-full">DOLDUR</Button>
              </Link>
            </CardFooter>
          </Card>
        ))}
      </CardContent>
    </Card>
  );
};

export default EditableForms;
