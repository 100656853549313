import api from "@/lib/api.service";

const URL = "/archivedform";

export const getAllArchivedForms = async () => {
  const { data } = await api.get(`${URL}/list`);
  console.log(data);
  return data;
};
export const formDeleteRequest = async (id) => {
  const { data } = await api.post(`${URL}/delete`, { _id: id });
  return data;
};

export const getbyFormIdArchivedForms = async (formId) => {
  const { data } = await api.get(`${URL}/get?formID=${formId}`);
  return data;
};
