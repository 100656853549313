import { createContext, useContext, useState } from "react";

// Pagination context'i oluşturuyoruz
const PaginationContext = createContext();

// Pagination provider
export function PaginationProvider({ children }) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  return (
    <PaginationContext.Provider value={{ pagination, setPagination }}>
      {children}
    </PaginationContext.Provider>
  );
}

// Pagination context kullanma hook'u
export function usePagination() {
  return useContext(PaginationContext);
}
